import { useEffect } from "react"
import ReactGA from "react-ga"
import { useLocation } from "react-router-dom"

ReactGA.initialize("G-655GWC7WRF")

const GoogleAnalytics = () => {
    const location = useLocation()
    useEffect(() => {
        ReactGA.pageview(location.pathname + location.search)
    }, [location])
    return null
}

export default GoogleAnalytics
