import { ChakraProvider, ChakraProviderProps, ColorMode, ColorModeScript } from "@chakra-ui/react"
import ReactDOM from "react-dom/client"
import App from "./App"
import theme from "./theme"

// force everything to dark
const dummyColorModeManager: ChakraProviderProps["colorModeManager"] = {
    type: "localStorage",
    ssr: false,
    get(init?: ColorMode) {
        return "dark" as ColorMode
    },
    set(value: ColorMode | "system") {},
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
    <>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <ChakraProvider theme={theme} colorModeManager={dummyColorModeManager}>
            <App />
        </ChakraProvider>
    </>
)
