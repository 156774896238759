import {
    Box,
    Heading,
    HStack,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Select,
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack,
    Stack,
    Text,
    Textarea,
} from "@chakra-ui/react"
import { FC, useMemo, useState } from "react"

const HEADER_CODE = `<script defer="defer" src="%WINDOW_URL%/embedder.js"></script>
<link href="%WINDOW_URL%/embedder.css" rel="stylesheet" />
`
const HTML_FORMAT = `<div trydyno-embed="" openai-model="%MODEL%" initial-prompt="%PROMPT%" initial-response="%RESPONSE%" max-tokens="%MAX_TOKENS%" box-rows="%BOX_ROWS%" model-temp="%TEMPERATURE%" top-p="%TOPP%">
    <noscript>Failed to load Dyno Embed: JavaScript must be enabled</noscript>
</div>`
const MODELS = [
    "text-davinci-003",
    "text-davinci-002",
    "text-davinci-001",
    "text-curie-001",
    "text-ada-001",
    "davinci-instruct-beta",
    "davinci",
    "curie-instruct-beta",
    "curie",
    "babbage",
    "ada",
]

const ConfigNumberInput: FC<{
    name: string
    min: number
    max: number
    step: number
    value: number
    onChange: (value: number) => void
}> = ({ name, min, max, step, value, onChange }) => {
    return (
        <HStack>
            <Text whiteSpace="nowrap">{name}</Text>
            <Slider onChange={(value) => onChange(value)} value={value} min={min} max={max} step={step}>
                <SliderTrack>
                    <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
            </Slider>
            <NumberInput onChange={(_, value) => onChange(value)} value={value} min={min} max={max} step={step}>
                <NumberInputField />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
        </HStack>
    )
}

const HomePage = () => {
    const [prompt, setPrompt] = useState("")
    const [response, setResponse] = useState("")
    const [maxTokens, setMaxTokens] = useState(256)
    const [selectedModel, setSelectedModel] = useState(MODELS[0])
    const [boxRows, setBoxRows] = useState(3)
    const [temperature, setTemperature] = useState(0.7)
    const [topP, setTopP] = useState(1)

    const windowUrl = window.location.protocol + "//" + window.location.host
    const headerCode = useMemo(() => HEADER_CODE.replaceAll("%WINDOW_URL%", windowUrl), [])
    const embedCode = useMemo(() => {
        return HTML_FORMAT.replaceAll("%WINDOW_URL%", windowUrl)
            .replaceAll("%MODEL%", selectedModel)
            .replaceAll("%PROMPT%", prompt.replaceAll("\n", "\\n"))
            .replaceAll("%RESPONSE%", response.replaceAll("\n", "\\n"))
            .replaceAll("%MAX_TOKENS%", "" + maxTokens)
            .replaceAll("%BOX_ROWS%", "" + boxRows)
            .replaceAll("%TEMPERATURE%", "" + temperature)
            .replaceAll("%TOPP%", "" + topP)
    }, [prompt, response, maxTokens, selectedModel, boxRows, temperature, topP])

    return (
        <Stack p="5" spacing="9">
            <Stack>
                <Heading size="xl">Configure Embed</Heading>
                <HStack>
                    <Stack flex="1">
                        <Heading size="md">Prompt</Heading>
                        <Textarea
                            onChange={(event) => setPrompt(event.currentTarget.value)}
                            value={prompt}
                            placeholder="Prompt text"
                            resize="none"
                        />
                    </Stack>
                    <Stack flex="1">
                        <Heading size="md">Response</Heading>
                        <Textarea
                            onChange={(event) => setResponse(event.currentTarget.value)}
                            value={response}
                            placeholder="Response text"
                            resize="none"
                        />
                    </Stack>
                </HStack>
                <HStack spacing="5">
                    <Box flex="1" maxW="25%">
                        <Heading size="md">Model</Heading>
                        <Select onChange={(event) => setSelectedModel(MODELS[event.currentTarget.selectedIndex])}>
                            {MODELS.map((model) => (
                                <option value={model} selected={selectedModel == model}>
                                    {model}
                                </option>
                            ))}
                        </Select>
                    </Box>
                    <Box flex="1">
                        <Heading size="md">Visual Config</Heading>
                        <ConfigNumberInput
                            name="Prompt Box Rows"
                            value={boxRows}
                            min={1}
                            max={100}
                            onChange={setBoxRows}
                            step={1}
                        />
                    </Box>
                    <Box flex="1">
                        <Heading size="md">Parameters</Heading>
                        <ConfigNumberInput
                            name="Max Tokens"
                            value={maxTokens}
                            min={1}
                            max={4000}
                            onChange={setMaxTokens}
                            step={1}
                        />
                        <ConfigNumberInput
                            name="Temperature"
                            value={temperature}
                            min={0}
                            max={1}
                            step={0.1}
                            onChange={setTemperature}
                        />
                        <ConfigNumberInput name="Top P" value={topP} min={0} max={1} step={0.1} onChange={setTopP} />
                    </Box>
                </HStack>
            </Stack>
            <Stack>
                <Heading size="xl">Add to Your Page</Heading>
                <Heading size="md">Header Code</Heading>
                <Text>You only need to place this code in your website's {"<head>"} tag once</Text>
                <Textarea value={headerCode} contentEditable={false} resize="none" rows={2} />
                <Heading size="md">Embed Code</Heading>
                <Textarea value={embedCode} contentEditable={false} resize="none" rows={2} />
                <Heading size="md">Preview</Heading>
                <Box borderWidth="2px" borderStyle="dashed" borderColor="white" borderRadius="md">
                    <script defer={true} src="/embedder.js"></script>
                    <div
                        key={Math.random()}
                        trydyno-embed=""
                        openai-model={selectedModel}
                        initial-prompt={prompt.replaceAll("\n", "\\n")}
                        initial-response={response.replaceAll("\n", "\\n")}
                        max-tokens={maxTokens}
                        box-rows={boxRows}
                        model-temp={temperature}
                        top-p={topP}
                    ></div>
                </Box>
            </Stack>
        </Stack>
    )
}

export default HomePage
