import { BrowserRouter, Route, Routes } from "react-router-dom"
import GoogleAnalytics from "./components/GoogleAnalytics"
import HomePage from "./pages/HomePage"

const App = () => {
    return (
        <BrowserRouter>
            <GoogleAnalytics />
            <Routes>
                <Route path="/" element={<HomePage />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App
